<template>
  <div :key="once" class="page_bg">
    <van-skeleton title avatar :row="23" :loading="loading">
      <!-- 返回和客服、收藏按钮 -->
      <div class="pro_del_banck_cart flex-r-sb-c">
        <van-image width="0.8rem" height="0.8rem"
                   src="https://oss.xiaoyi120.com/shop2.0/public/top_back_round.png" fit="fill"
                   @click="backPath"
        />
        <div>
          <!-- <van-image v-if="parseInt(proDetailInfo.isCollection) === 0" width="0.8rem" height="0.8rem"
                     src="https://oss.xiaoyi120.com/shop2.0/product/pro_favor0.png" fit="fill"
                     @click="doClickFavor(true)"
          />
          <van-image v-else width="0.8rem" height="0.8rem"
                     src="https://oss.xiaoyi120.com/shop2.0/product/pro_favor1.png" fit="fill"
                     @click="doClickFavor(false)"
          /> -->
          <van-image width="0.8rem" height="0.8rem" style="margin-left: 0.2rem;"
                     src="https://oss.xiaoyi120.com/shop2.0/product/pro_kefu.png" fit="fill"
                     @click="openKeFu"
          />
        </div>
        <!-- <van-badge :content="$store.getters.totalShopping" color="#EA5C36">
          <van-image width="0.8rem" height="0.8rem"
                     src="https://oss.xiaoyi120.com/shop2.0/public/top_cart_round.png" fit="fill"
                     @click="$router.push('/cart/cart')"
          />
        </van-badge> -->
      </div>
      <!-- 商品产品图 -->
      <div class="pro_del_banner">
        <van-swipe class="my-swipe" autoplay="false" style="height:7.49rem">
          <van-swipe-item v-for="(banner,index_banner) in proDetailInfo.proPrcUrls" :key="index_banner">
            <template v-if="index_banner === 0 && proDetailInfo.videoCode && proDetailInfo.videoUrl">
              <tx-video v-if="showVideo" ref="txVideo" :options="videoOptions" />
            </template>
            <template v-else>
              <van-image width="10rem" height="7.49rem" :src="banner" fit="fill" @click="showPic(index_banner)" />
            </template>
          </van-swipe-item>
        </van-swipe>
        <!-- <van-image v-if="parseInt(proDetailInfo.releaseFlag) === 3" width="10rem" height="0.64rem"
                   src="https://oss.xiaoyi120.com/shop2.0/product/presell_detail.png"
        /> -->
        <div v-if="parseInt(proDetailInfo.releaseFlag) === 3" class="presell_box flex-r-sb-c">
          <div class="presell_msg flex-c-s-s">
            <div class="msg_title">预售商品</div>
            <div class="msg_tips">商品采购中，预计7-14天内到仓即可发货</div>
          </div>
          <div class="presell_bottom flex-r-c-c" @click="orderPresell">
            <van-image width="0.32rem" height="0.32rem" style="margin-right:0.1rem" :src="is_presell ? 'https://oss.xiaoyi120.com/shop2.0/product/presell_1.png'
              :'https://oss.xiaoyi120.com/shop2.0/product/presell_0.png'"
            />
            {{ is_presell ? '已订阅':'到货提醒' }}
          </div>
        </div>
      </div>
      <!-- 产品主要信息 -->
      <div class="pro_del_info">
        <!-- 基础信息 -->
        <div class="info_name van-multi-ellipsis--l2 flex-r-s-c">
          <van-image v-if="isShowYanxuan" width="0.75rem" height="0.43rem"
                     src="https://oss.xiaoyi120.com/shop2.0/vip/vip_pro_tag.png"
          />
          {{ proDetailInfo.proName }}
        </div>
        <div class="info_price_salenNum flex-r-sb-c" style="width:95%">
          <div class="info_price_new flex-r-s-s">
            <div style="margin-left: 0.1rem;">
              <span style="font-size: 0.52rem;"><span
                style="font-size: 0.32rem;"
              >￥</span>{{ proDetailInfo.proPrice }}</span>
            </div>
            <div v-if="proDetailInfo.saleUnderlineFlag === 1"
                 style="text-decoration:line-through;color: #999999;font-size: 0.32rem;margin-top:0.15rem;margin-left:0.1rem"
            >
              ￥{{ proDetailInfo.originPrice }}
            </div>
          </div>
          <div class="info_salenNum">
            <van-stepper v-if="proDetailInfo.num > 0 || proDetailInfo.num === ''" v-model="proDetailInfo.num"
                         :step="proDetailInfo.minQuantity" :min="0" integer :allow-empty="true" input-width="1rem"
                         button-size="0.5rem" theme="round" @change="changeNum" @focus="focusInput" @blur="blurInput"
            />
            <van-icon v-else name="add" color="#0767AB" size="0.55rem" @click="addNum" />
          </div>
        </div>
        <div v-if="proDetailInfo.formType === 3" class="limit_er_tip flex-r-s-c">
          <div class="num_store">剩余 {{ proDetailInfo.storeTotal }} 个</div>
        </div>
      </div>
      <!-- 分期信息 -->
      <div v-if="proDetailInfo.paymentDayStatus === 1" class="pro_del_info2 flex-c-s-s">
        <div class="info2_item flex-r-s-s" style="border:none">
          <div class="info2_item_label">商品分期</div>
          <div class="info2_item_stages flex-r-sb-c">
            <div class="stages_box flex-r-s-c">
              <div class="stages_title">分期</div>
              <div class="stages_contant">￥{{ proDetailInfo.paymentAmount }} X {{ proDetailInfo.paymentNum }}期</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品其他信息 -->
      <div class="pro_del_info2 flex-c-s-s" style="padding:0rem;padding-left:0.37rem">
        <div class="info2_item flex-r-s-s">
          <div class="info2_item_label">起售数量</div>
          <div class="info2_item_value">{{ proDetailInfo.minQuantity }}{{ proDetailInfo.unitName || '' }}</div>
        </div>
      </div>
      <!-- 产品其他信息 -->
      <div class="pro_del_info2 flex-c-s-s">
        <!-- <div class="info2_item flex-r-s-s" @click="brand_ser_click(proDetailInfo.proBrand)"> -->
        <div class="info2_item flex-r-s-s">
          <div class="info2_item_label">商品品牌</div>
          <div class="info2_item_value flex-r-sb-c">
            <span>{{ proDetailInfo.proBrand }}</span>
            <!-- <van-icon name="arrow" color="#999999" style="margin-right:0.37rem" /> -->
          </div>
        </div>
        <div class="info2_item flex-r-s-s">
          <div class="info2_item_label">规格型号</div>
          <div class="info2_item_value">{{ proDetailInfo.proModel }}</div>
        </div>
        <div class="info2_item flex-r-s-s">
          <div class="info2_item_label">商品效期</div>
          <div class="info2_item_value">{{ proDetailInfo.validityPeriod }} (仅做参考)</div>
        </div>
        <div v-if="proDetailInfo.suggestPrice" class="info2_item flex-r-s-s">
          <div class="info2_item_label">建议零售价</div>
          <div class="info2_item_value">￥{{ proDetailInfo.suggestPrice }}</div>
        </div>
      </div>
      <!-- 相关商品 -->
      <div v-if="proDetailInfo.unionItemResDtos.length>0" class="pro_like flex-c-s-s">
        <div class="like_title">相关商品</div>
        <div class="like_lists flex-r-s-s">
          <div v-for="(like,like_index) in proDetailInfo.unionItemResDtos" :key="like_index" class="like_list"
               @click="reLoadPro(like.otherId)"
          >
            <van-image width="2.93rem" height="2.19rem" :src="like.proIconUrl" />
            <div class="like_list_name van-ellipsis">{{ like.proName }}</div>
          </div>
        </div>
      </div>
      <!-- 产品相关详情介绍图 -->
      <div class="pro_del_imgs">
        <van-image v-for="(img01,index_img01) in imgView" :key="index_img01" width="10rem" :src="img01" fit="fill"
                   style="margin-bottom:-0.21rem;margin-top:-0.22rem" @click="showDetailPic(index_img01)"
        />
      </div>
    </van-skeleton>
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />

    <!-- 底部操作 -->
    <div class="classify_sub_cart flex-r-sa-c">
      <div class="classify_sub_cart_num" @click="toView">
        <van-image v-if="cartNum > 0" width="1.39rem" height="1.39rem"
                   src="https://oss.xiaoyi120.com/shop2.0/product/classify_cart11.png"
        />
        <van-image v-else width="1.39rem" height="1.39rem"
                   src="https://oss.xiaoyi120.com/shop2.0/product/classify_cart10.png"
        />
        <div v-if="cartNum > 0" :class="cartNum > 9 ? 'nums_posite2':'nums_posite1' ">{{ cartNum }}</div>
      </div>
      <div class="classify_sub_cart_info flex-c-s-s">
        <div class="info_money">实付金额:<span style="color:#E22D1D">￥</span><span
          style=" font-size: 0.48rem;color:#E22D1D"
        >{{ totalMoney }}</span></div>
        <div class="info_money2">优惠减￥{{ discountPrice }}</div>
      </div>
      <div />
      <div />
      <div />
      <div />
      <div :class="cartNum > 0 ? 'classify_sub_cart_button1':'classify_sub_cart_button0'" @click="toView">订单预览</div>
    </div>
    <!-- 优惠信息弹框 -->
    <van-overlay :show="activityShow" @click="activityShow = false">
      <div class="overlay_activity flex-c-s-c">
        <van-image width="100%" height="2.4rem" src="https://oss.xiaoyi120.com/shop2.0/product/pro_activity_bg.png"
                   fit="fill" class="overlay_activity_img"
        />
        <div class="overlay_activity_box flex-c-c-s">
          <!-- 返券 -->
          <div v-if="proDetailInfo.activity.productReturnCouponItemRes" class="info2_item_value flex-r-s-c">
            <van-image width="1.07rem" height="0.59rem"
                       src="https://oss.xiaoyi120.com/shop2.0/product/pro_coupon_01.png"
                       fit="fill"
            />
            <div class="coupon_name">
              返{{ proDetailInfo.activity.productReturnCouponItemRes.money }}元现金券
            </div>
          </div>
          <!-- 满减 满赠 -->
          <template v-if="proDetailInfo.activity.activityActionDetailRes.length > 0">
            <div v-for="(activityActionDetailRes,activityActionDetailRes_index) in proDetailInfo.activity.activityActionDetailRes"
                 :key="activityActionDetailRes_index" class="info2_item_value flex-r-c-s"
            >
              <van-image v-if="activityActionDetailRes.actionType === 'MJ_AMOUNT_AC'" width="1.07rem" height="0.59rem"
                         src="https://oss.xiaoyi120.com/shop2.0/product/pro_coupon_02.png"
                         fit="fill"
              />
              <van-image v-if="activityActionDetailRes.actionType === 'ZS_SKU_NUM_AC'" width="1.07rem" height="0.59rem"
                         src="https://oss.xiaoyi120.com/shop2.0/product/pro_coupon_03.png"
                         fit="fill"
              />
              <div class="coupon_name">{{ activityActionDetailRes.name }}</div>
            </div>
          </template>
          <!-- 按钮 -->
          <div class="overlay_activity_box_button" @click="activityShow = false"> 知道了 </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import './index.scss'
import {
  mallPreferential_productDetail, mallPreferential_addCart, mallPreferential_delCart, mallPreferential_cartCalculate,
  user_collection, user_collection_delete, mallProduct_mallProductDetailH_VIP,
  mallProduct_getArrivalReminder, mallProduct_setArrivalReminder
} from '@/api/pro'
import txVideo from '@/components/cs-txVideo/cs-txVideo'
import kefuBtn from '@/components/kefu_btn'
import { ImagePreview } from 'vant'
export default {
  name: 'ProDetail',
  components: { txVideo, kefuBtn },
  data() {
    return {
      productModuleId: '',
      once: 1,
      onceList: [],
      loading: true,
      pid: 0,
      companyId: '1000',
      videoOptions: { file_id: '387702299824584719', appId: '1304134186' },
      proDetailInfo: {},
      isShowYanxuan: false,
      showVideo: false,
      seckillTime: '',
      isSeckill: false,
      isVip: false,
      imgView: [],
      is_presell: false,
      // 优惠信息弹框
      activityShow: false,

      cartNum: 0,
      discountPrice: '0.00',
      totalMoney: '0.00'
    }
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      window.sessionStorage.setItem('lasterRouter', from.path)
    })
  },
  created() {
    this.productModuleId = this.$route.query.productModuleId
    if (window.name === '') {
      console.log('首次被加载')
      window.name = 'proDetail' // 在首次进入页面时我们可以给window.name设置一个固定值
    } else if (window.name === 'isReload') {
      sessionStorage.setItem('jump', true)
    }
    this.pid = this.$route.query.productId

    const parms = { step: this.once, id: this.pid }
    this.onceList.push(parms)
    this.isVip = this.$route.query.isVip
    if (this.$route.query.isVip) {
      this.getDetail_vip()
    } else {
      this.getDetail()
    }
  },
  methods: {
    backPath() {
      window.history.back()
    },
    getNum() {
      mallPreferential_cartCalculate({ productModuleId: this.productModuleId }).then(res => {
        this.cartNum = res.data.typeNum
        this.discountPrice = res.data.discountPrice
        this.totalMoney = res.data.totalPrice
        sessionStorage.setItem('cart_pro_id', JSON.stringify({ productId: this.pid, num: this.proDetailInfo.num }))
      })
    },
    getDetail() {
      mallPreferential_productDetail(this.pid).then(res => {
        this.proDetailInfo = res.data
        // 判断严选
        // const tagId = parseInt(res.data.tagId)
        // if (tagId === 274 || tagId === 280 || tagId === 281 || tagId === 283 || tagId === 284) { this.isShowYanxuan = true }
        this.isShowYanxuan = res.data.isStrict
        // 添加视频进入swipe
        if (this.proDetailInfo.videoCode && this.proDetailInfo.videoUrl) {
          this.proDetailInfo.proPrcUrls.unshift(this.proDetailInfo.videoCode)
          this.videoOptions.file_id = this.proDetailInfo.videoCode
        }
        this.imgView = [...res.data.proDetail, ...res.data.proTechnicalParameter, ...res.data.proAfterInformation]

        // 判断是否预售预约
        if (parseInt(res.data.releaseFlag) === 3) {
          mallProduct_getArrivalReminder({ mallProductId: this.pid }).then(resx => { this.is_presell = parseInt(resx.data.reminderFlag) === 2 })
        }
        console.log(this.proDetailInfo.proPrcUrls)
        this.loading = false
        this.showVideo = true
        this.getNum()
      })
    },
    getDetail_vip() {
      mallProduct_mallProductDetailH_VIP(this.pid).then(res => {
        this.proDetailInfo = res.data
        // 添加视频进入swipe
        if (this.proDetailInfo.videoCode && this.proDetailInfo.videoUrl) {
          this.proDetailInfo.proPrcUrls.unshift(this.proDetailInfo.videoCode)
          this.videoOptions.file_id = this.proDetailInfo.videoCode
        }

        console.log(this.proDetailInfo.proPrcUrls)
        this.loading = false
        this.showVideo = true
      })
    },
    reLoadPro(id) {
      this.once += 1
      const parms = { step: this.once, id: id }
      this.onceList.push(parms)
      this.pid = id
      this.getDetail()
      this.$forceUpdate()
    },
    brand_ser_click(val) {
      this.$router.push('/product/brandList?name=' + val)
      // if (val === '贝思倍健') {
      //   this.$router.push('/product/subjectPage_bsbj')
      // } else {
      //   this.$router.push('/product/brandList?name=' + val)
      // }
    },
    // 预约预售提醒
    orderPresell() {
      if (!this.is_presell) {
        mallProduct_setArrivalReminder({ mallProductId: this.pid }).then(() => {
          this.is_presell = true
          this.$toast({ message: '预约成功！', duration: 1 * 1000 })
        })
      }
    },
    // 预览
    showPic(index) {
      if (this.proDetailInfo.videoUrl) (index + 1)
      ImagePreview({
        images: this.proDetailInfo.proPrcUrls,
        startPosition: index
      })
    },
    showDetailPic(index) {
      ImagePreview({
        images: this.imgView,
        startPosition: index
      })
    },
    // 收藏
    doClickFavor(status) {
      if (status) {
        user_collection({ productId: this.pid }).then(res => {
          this.proDetailInfo.isCollection = 1
          this.$toast({ message: status ? '收藏成功！' : '取消成功！', duration: 1 * 1000 })
        })
      } else {
        user_collection_delete(this.pid).then(res => {
          this.proDetailInfo.isCollection = 0
          this.$toast({ message: status ? '收藏成功！' : '取消成功！', duration: 1 * 1000 })
        })
      }
    },
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) },
    // =====================================activity===================================
    openActivity() {
      this.activityShow = true
    },
    // ===================================mask========================================
    // 修改商品数量
    addNum() {
      mallPreferential_addCart({ productModuleId: this.productModuleId, productId: this.pid, num: parseInt(this.proDetailInfo.minQuantity) }).then(res => {
        this.proDetailInfo.num = parseInt(this.proDetailInfo.minQuantity)
        this.getNum()
      })
    },
    focusInput() { this.proDetailInfo.num = '' },
    blurInput() {
      if (this.proDetailInfo.num === '') {
        mallPreferential_delCart({ productModuleId: this.productModuleId, productId: this.pid }).then(res => { this.getNum() })
        this.proDetailInfo.num = 0
      }
    },
    changeNum(num) {
      if (num > 0) {
        mallPreferential_addCart({ productModuleId: this.productModuleId, productId: this.pid, num: num }).then(res => { this.getNum() })
      } else if (parseInt(num) === 0) {
        mallPreferential_delCart({ productModuleId: this.productModuleId, productId: this.pid }).then(res => { this.getNum() })
      }
    },

    toView() {
      if (this.cartNum > 0) {
        let url = ''
        url = `/order/subOrder_cart?productModuleId=` + this.productModuleId
        this.$router.push(url)
      } else {
        this.$toast({ message: '请先添加商品', duration: 1 * 1000 })
      }
    }

  }
}
</script>

<style>
.info_salenNum .van-stepper--round .van-stepper__minus {
  border: 0.03rem solid #e2e2e2;
  color: #000;
}
.info_salenNum .van-stepper--round .van-stepper__plus {
  background-color: #0767ab;
}
</style>
